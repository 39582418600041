import React, { useEffect, useRef, useState } from "react";

import { Link } from "gatsby-plugin-react-i18next";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import GtmService from "../service/gtmService";

import MiraclLogo from "../assets/elements/MiraclLogo";
import NavIcon from "../assets/icons/nav.svg";
import MailIcon from "../assets/icons/sendMail.svg";
import TelIcon from "../assets/icons/telephone.svg";
import { Button } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";
import DesktopNav from "./header/DesktopNav";
import MobileNav from "./header/MobileNav";

const TelData = () => (
    <>
        <StyledTelIcon />
        <ContactData>+43 670 3085171</ContactData>
    </>
);

const Header = () => {
    const wrapperRef = useRef();
    const [showMobileNav, setShowMobileNav] = useState(false);

    const closeMobileNav = () => {
        setShowMobileNav(false);
    };

    const onGetCredit = () => {
        const target = document.querySelector("#process-explanation-short");
        const position = target.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({ top: position, behavior: "smooth" });
    };

    useEffect(() => {
        const servicesToggleDesktop = wrapperRef.current.querySelector(".servicesToggle.desktop");
        for (const link of wrapperRef.current.querySelectorAll("a")) {
            link.addEventListener("click", () => {
                servicesToggleDesktop.classList.add("isClosed");
                closeMobileNav();
            });
        }
    }, []);

    return (
        <Wrapper ref={wrapperRef}>
            <NavToggler onClick={() => setShowMobileNav(!showMobileNav)}>
                <NavIcon stroke={colors.primary} width={20} height={14} />
            </NavToggler>
            <LogoLink to="/">
                <MiraclLogo />
            </LogoLink>
            <DesktopNav />
            <ContactWrapper>
                <ContactLink
                    href="mailto:kontakt@miracl.at"
                    onClick={() => {
                        GtmService.instance.trackEvent({
                            event: "contactClickedHeader",
                        });
                    }}
                >
                    <StyledMailIcon />
                    <ContactData>kontakt@miracl.at</ContactData>
                </ContactLink>
            </ContactWrapper>
            <ButtonWrapper>
                <HeaderButton onClick={onGetCredit}>Wie funktioniert's?</HeaderButton>
            </ButtonWrapper>
            <CSSTransition
                in={showMobileNav}
                timeout={300}
                unmountOnExit
                onEnter={(element) => {
                    element.style.maxHeight = `${element.scrollHeight}px`;

                    for (const link of element.querySelectorAll("a")) {
                        link.addEventListener("click", () => {
                            closeMobileNav();
                        });
                    }
                }}
            >
                <MobileNav />
            </CSSTransition>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 80px rgba(0, 0, 0, 0.16);
    display: flex;
    font-size: 16px;
    height: 52px;
    padding: 0 20px 0 25px;
    position: relative;
    white-space: nowrap;
    z-index: 5;

    @media (min-width: ${breakpoints.tabletMin}) and (max-width: ${breakpoints.tabletMax}) {
        height: 62px;
    }
    @media (min-width: ${breakpoints.desktopMin}) {
        font-size: 18px;
        height: 75px;
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (min-width: ${breakpoints.desktopBigMin}) {
        font-size: 20px;
    }
`;

const LogoLink = styled(Link)`
    display: inline-flex;

    svg {
        height: 22px;
        margin-bottom: 2px;
        width: 70px;

        @media (min-width: ${breakpoints.tabletMin}) and (max-width: ${breakpoints.tabletMax}) {
            height: 26px;
            margin-bottom: 6px;
            width: 84px;
        }
        @media (min-width: ${breakpoints.desktopMin}) {
            height: 32px;
            margin-bottom: 6px;
            width: 102px;
        }
    }
`;

const NavToggler = styled.span`
    cursor: pointer;
    display: none;
    height: 14px;
    margin-right: 7px;
    margin-top: 6px;
    padding: 5px 5px 5px;

    @media (max-width: ${breakpoints.mobileBigMax}) {
        display: inline-flex;
    }
`;

const ContactWrapper = styled.div`
    display: none;

    @media (min-width: ${breakpoints.desktopBigMin}) {
        display: inline-flex;
    }
`;

const ContactLinkCss = css`
    align-items: center;
    display: flex;
    margin-left: 15px;

    @media (min-width: ${breakpoints.tabletMin}) {
        margin-left: 20px;
    }
    @media (min-width: ${breakpoints.desktopBigMin}) {
        margin-left: 36px;
    }
`;

const ContactLink = styled.a`
    ${ContactLinkCss}
`;

const TelMobile = styled(ContactLink)`
    @media (min-width: ${breakpoints.tabletMin}) {
        display: none;
    }
`;

const TelDesktop = styled(Link)`
    ${ContactLinkCss}

    @media (max-width: ${breakpoints.mobileMax}) {
        display: none;
    }
`;

const ContactData = styled.span`
    display: none;
    padding-left: 15px;

    @media (min-width: ${breakpoints.desktopMin}) {
        display: block;
    }
`;

const StyledTelIcon = styled(TelIcon)`
    height: 24px;
    width: 24px;

    @media (min-width: ${breakpoints.desktopMin}) {
        height: 36px;
        width: 36px;
    }
`;

const StyledMailIcon = styled(MailIcon)`
    height: 22px;
    width: 51px;

    @media (min-width: ${breakpoints.desktopMin}) {
        height: 33px;
        width: 77px;
    }
`;

const ButtonWrapper = styled.div`
    margin-left: auto;

    @media (min-width: ${breakpoints.desktopMin}) {
        margin-left: 28px;
    }

    @media (min-width: ${breakpoints.desktopBigMin}) {
        margin-left: 36px;
    }
`;

const HeaderButton = styled(Button)`
    border-radius: 20px;
    display: inline-flex;
    font-size: 13px;
    height: 26px;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        font-size: 17px;
        height: 34px;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export default Header;
